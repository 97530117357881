import logo from './logo.svg';
import './App.css';
import { Component, createRef, useRef } from 'react';

function ConsentForm(props) {
  return (
    <div>
      <div className="background-overlay"></div>
      <div className="consent-form card">
        <div className="card-content">
          <div className="content">
            <b>In loving memory of my dad, Joseph Steady</b>

            <p>
            This is intended to be a digital memorial for my dad.
            I hope to share with you a tiny glance of my dad's life.
            </p>
            <button className="button is-link" id="mybutton" onClick={props.enableVideo}>Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
}

function VideoBackground(props) {
  let videoClass = "video-stretch" //  props.stretch ? "video-stretch" : "video-no-stretch"

  if (props.screenWidth < props.screenHeight) {
    videoClass = "video-stretch-mobile"
  }
    
  return (<video ref={props.vidRef} autoPlay loop src={props.backgroundVideoUrl} className={videoClass} playsInline></video>)
}

function Blurb(props) {
  let divClass = window.innerWidth < window.innerHeight ? "content-bottom" : "content-right"
  let otherDevice = window.innerWidth < window.innerHeight ? "laptop" : "phone"
  let buttonText = "Share a memory"
  return (
    <div className={divClass}>
      <p>
        This is a small collection of videos I have with my dad. I wish we had more.
        Feel free to watch them. There's also a different set of videos if you watch on your {otherDevice}.
      </p>
      <div className="contentBar-buttons">
        <button className="button play-another" onClick={props.switchVideo}>Play another</button>
      </div>
    </div>
  )
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = { backgroundVideoUrl: "xyz", screenWidth: 0, videoStretch: false, muted: true, videoIndex: null, isVertical: window.innerWidth < window.innerHeight }
    // we have to bind like this in order for this.toggleMute to work in the render func
    this.toggleMute = this.toggleMute.bind(this)
    this.switchVideo = this.switchVideo.bind(this)
    this.enableVideo = this.enableVideo.bind(this)
    
    this.bgVidRef = createRef()
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // TODO idk why the setState for window width doesn't work immediately... 
    this.switchVideo();
  }

  resize() {
    const isVertical = window.innerWidth < window.innerHeight;

    console.log('vertical?', isVertical, this.state.isVertical)

    // if the orientation is now different than previous orientation, fetch a new video
    if (isVertical != this.state.isVertical) {
      this.switchVideo()
    }

    this.setState({screenWidth: window.innerWidth, isVertical: isVertical});
  }

  toggleMute() {
    this.setState({muted: !this.state.muted})
  }

  switchVideo() {
    let url = `https://dad-steady-backend.herokuapp.com/api/get-video-background?screen-width=${window.innerWidth}&screen-height=${window.innerHeight}`
    if (this.state.videoIndex != null) {
      url = url + `&video-index=${this.state.videoIndex}`
    }

    // TODO make the domain configurable...
    fetch(url)
    .then(res => res.json())
    .then((data) => {
      console.log('data', data)
      this.setState({ backgroundVideoUrl: data.videoUrl, videoStretch: data.videoStretch, videoIndex: data.videoIndex })
      console.log(data.videoStretch, this.state)
      this.render()
    })
    .catch(console.log)
  }

  enableVideo() {
    this.bgVidRef.current.play()
    this.setState({clicked: true})
  }

  render() {
      // load a consent form initially that is actually a play button
      // hide the consent form once clicked
      return (
      <div className="App">
        { !this.state.clicked ? <ConsentForm enableVideo={this.enableVideo}/> : null }
        <VideoBackground vidRef={this.bgVidRef} screenHeight={window.innerHeight} screenWidth={window.innerWidth} backgroundVideoUrl={this.state.backgroundVideoUrl} stretch={this.state.videoStretch} muted={false}/>
        { this.state.clicked ? <Blurb toggleMute={this.toggleMute} switchVideo={this.switchVideo}/> : null }
      </div>
      );
  }
}

export default App;
